import { Modal } from 'bootstrap';
import $ from "jquery";

$(function () {
    $('body').on('click', '.btn-modal-confirm-danger', function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        $('#modal-confirm-danger .modal-body-content').html($(this).data('text'));
        $('#modal-confirm-danger .btn-danger').attr('href', $(this).attr('href'));

        var modalConfirmDanger = new Modal(document.getElementById('modal-confirm-danger'));
        modalConfirmDanger.toggle();
    });

    $('body').on('change', '.auto-submit', function (event) {
        if ($(this).is(':checkbox')) {
            $(this).closest("form").trigger('submit');
        }
    });

    $('.file-edit').on('click', function () {
        Livewire.emit('loadFileForm', $(this).data('file-id'));
    });

    if ($('#file-verification-process').length) {
        setInterval(() => {
            $.ajax({
                url: '/product/' + $('#file-verification-process').data('product-id') + '/version/' + $('#file-verification-process').data('version-id') + '/isChecked',
                dataType: 'json',
                success: function (data) {
                    if (data.isChecked) {
                        location.reload();
                    }
                }
            });
        }, 5000);
    }
});