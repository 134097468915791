//
// choices.js
// Theme module
//

import Choices from 'choices.js';

const toggles = document.querySelectorAll('[data-choices]');

toggles.forEach((toggle) => {
  const elementOptions = toggle.dataset.choices ? JSON.parse(toggle.dataset.choices) : {};

  const defaultOptions = {
    classNames: {
      allowHTML: false,
      containerInner: toggle.className,
      input: 'form-control',
      inputCloned: 'form-control-sm',
      listDropdown: 'dropdown-menu',
      itemChoice: 'dropdown-item',
      activeState: 'show',
      selectedState: 'active',
    },
    searchFields: ['label', 'value', 'customProperties.description'],
    searchResultLimit: 30,
    shouldSort: false
  };

  const options = {
    ...elementOptions,
    ...defaultOptions,
  };

  new Choices(toggle, options);
});

// Make available globally
window.Choices = Choices;
