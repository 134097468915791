//
// Sortable.js
// Theme module
//

import Sortable from 'sortablejs';
import $, { data } from "jquery";

const toggles = document.querySelectorAll('[data-sortable]');
const sortableElement = new Array();

toggles.forEach((toggle) => {
    const elementOptions = toggle.dataset.sortable ? JSON.parse(toggle.dataset.sortable) : {};

    const defaultOptions = {
        group: "position",
        animation: 150,
        swapThreshold: 1,
        store: {
            get: function (sortable) {
                var order = localStorage.getItem(sortable.options.group);
                return order ? order.split('|') : [];
            },
            set: function (sortable) {
                var order = sortable.toArray();
                order.forEach((element, index) => {
                    document.querySelector('[data-id="' + element + '"]').querySelector('input').value = index;
                    document.querySelector('.number-position-' + element).innerHTML = index + 1;
                });

                $.ajax({
                    url: '/category/save',
                    data: $('#form-ajax').serialize(),
                    type: 'POST'
                })
            }
        },
        onEnd: function (evt) {
            evt.item.querySelectorAll('input').value = evt.newIndex;
        },
    };

    const options = {
        ...elementOptions,
        ...defaultOptions,
    };

    sortableElement.push(Sortable.create(toggle, options));
});

$('.btn-top-position').on('click', function (e) {
    $(this).closest('.col-4').prependTo('[data-sortable]');
    window.SortableElement.forEach((element) => {
        element.save();
    });
});

$('.btn-bottom-position').on('click', function (e) {
    $(this).closest('.col-4').appendTo('[data-sortable]');
    window.SortableElement.forEach((element) => {
        element.save();
    });
});

// Make available globally
window.Sortable = Sortable;
window.SortableElement = sortableElement;
