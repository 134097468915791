import Resumable from 'resumablejs';
import $ from "jquery";

const toggles = document.querySelectorAll('[data-resumable]');
var currentUploads = 0;

toggles.forEach((toggle) => {
    const elementOptions = toggle.dataset.resumable ? JSON.parse(toggle.dataset.resumable) : {};

    const defaultOptions = {
        target: '/product/version/file',
        forceChunkSize: true,
        testChunks: false,
        simultaneousUploads: 3,
        headers: {
            'Accept': 'application/json'
        },
    };

    const options = {
        ...defaultOptions,
        ...elementOptions,
    };

    options.chunkSize = options.chunkSize * 1024 * 1024;

    var resumable = new Resumable(options);
    resumable.assignBrowse(toggle);

    resumable.on('fileAdded', function (file) { // trigger when file picked
        currentUploads = currentUploads + 1;
        var progressBar = $(toggle).next().find('.progress-bar');
        progressBar.css('width', '0%');
        progressBar.text('0%');
        progressBar.removeClass('bg-success');

        $(toggle).next().removeClass('d-none');
        $(toggle).addClass('d-none');

        resumable.upload() // to actually start uploading.
    });

    resumable.on('fileProgress', function (file) { // trigger when file progress update
        var value = Math.floor(file.progress() * 100);
        var progressBar = $(toggle).next().find('.progress-bar');
        progressBar.css('width', value + '%');
        progressBar.text(value + '%');
    });

    resumable.on('fileSuccess', function (file, response) { // trigger when file upload complete
        currentUploads = currentUploads - 1;

        $(toggle).next().find('.progress-bar').addClass(
            'bg-success');
        $(toggle).next().find('.progress-bar').removeClass(
            'progress-bar-striped');
        $(toggle).next().find('.progress-bar').removeClass(
            'progress-bar-animated');

        if (currentUploads == 0) {
            location.reload();
        }
    });
});