//
// flatpickr.js
// Theme module
//

import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"

flatpickr.localize(French);

const toggles = document.querySelectorAll('[data-flatpickr]');

toggles.forEach((toggle) => {
  const options = toggle.dataset.flatpickr ? JSON.parse(toggle.dataset.flatpickr) : {"locale": French};

  flatpickr(toggle, options);
});


// Make available globally
window.flatpickr = flatpickr;
